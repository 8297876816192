@import '@angular/cdk/overlay-prebuilt.css';
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@mixin clickable() {
  cursor: pointer;
  &:active:hover {
    transform: scale(var(--v-click-scale));
  }
}

:root {
--font-sans: ''
}

:root {
  --v-click-scale: .99;
  --theme: hsl(0 0% 100%);
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;;
  --popover-foreground: 240 10% 3.9%;
  --primary: 240 5.9% 10%;
  --primary-foreground: 0 0% 98%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 90.2% 45.1%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 240 5.9% 10%;
  --radius: 0.5rem;
  --theme-name: light;
}

.dark {
  --v-click-scale: .99;
  --theme: hsl(240 10% 3.9%);
  --background: 240 10% 3.9%;
  --foreground: 0 0% 98%;
  --card: 240 10% 3.9%;
  --card-foreground: 0 0% 98%;
  --popover: 240 10% 3.9%;
  --popover-foreground: 0 0% 98%;
  --primary: 0 0% 98%;
  --primary-foreground: 240 5.9% 10%;
  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;
  --muted: 240 3.7% 15.9%;
  --muted-foreground: 240 5% 64.9%;
  --accent: 240 3.7% 15.9%;
  --accent-foreground: 0 0% 98%;
  --destructive: 0 82% 59%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 3.7% 15.9%;
  --input: 240 3.7% 15.9%;
  --ring: 240 4.9% 83.9%;
  --theme-name: dark;
}

input {
  color-scheme: var(--theme-name);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cg-md {
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  font-style: normal;
}

.cg-sb {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  font-style: normal;
}

.clickable {
  @include clickable();
}

button {
  @include clickable();
}

.lg-thumb-item {
  &:hover {
    border: 2px solid #7e0404 !important;
  }

  &.active {
    border: 2px solid #a90707 !important;
  }
}

.dialogTop {
  z-index: 2000;
}

.lg-backdrop {
  z-index: 700 !important;
}

.lg-outer {
  z-index: 710 !important;
}

html {
  scroll-behavior: smooth;
}

/* Hide scrollbar for the container */
.hide-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.hide-scroll {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.box-shadow {
  box-shadow:
    0 0 20px rgba(255, 255, 255, 0.2),
    0 0 30px rgba(255, 255, 255, 0.1),
    0 0 40px rgba(255, 255, 255, 0.05);
}

.box-shadow:before {
  filter: blur(20px);
  opacity: 0.7;
}
